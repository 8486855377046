<template>
  <footer class="footer">
    <b-container>
      <b-row>
        <b-col lg="3" md="3">
          <section class="footer__main-routes">
            <h2 class="sub-heading sub-heading--white">{{ $t("heading.site_map") }}</h2>
            <b-nav vertical>
              <b-nav-item
                v-for="(nav, index) in navLinks"
                :key="index"
                :to="nav.link"
              >{{ $t(`label.${nav.name}`) }}</b-nav-item>
            </b-nav>
          </section>
        </b-col>
        <b-col lg="3" md="3" sm="12">
          <ContactList />
        </b-col>
        <b-col lg="3" md="3" sm="12">
          <ServicesList />
        </b-col>
        <b-col lg="3" md="3" sm="12">
          <Socials />
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" class="text-sm-center">
          <p class="footer__copyright my-4">{{ getCurrentYear }} {{ $t("hint.copyrights") }}</p>
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  components: {
    ContactList: () => import("@/components/Contacts/ContactList"),
    Socials: () => import("@/components/Contacts/Socials"),
    ServicesList: () => import("./components/services")
  },
  data() {
    return {
      navLinks: [
        { name: "home", link: "/" },
        { name: "about_us", link: "/about-us" },
        { name: "services", link: "/services" },
        { name: "store", link: "/store" },
        // { name: "managers_council", link: "/managers-council" },
        { name: "contact_us", link: "/contact-us" },
        { name: "usage_policy", link: "/usage-policy" },
        { name: "clients", link: "/clients" },
        { name: "blogs", link: "/blog" },
      ]
    };
  },
  computed: {
    getCurrentYear() {
      return new Date().getFullYear();
    }
  }
};
</script>


